import {Pagination, Space, Table} from "antd";
import React, {useMemo, useState} from "react";
import {PaginationConfig} from "antd/lib/pagination";
import {useTranslation} from "react-i18next";
import {useGetAllTheAssetsOfTheHistoryOfTheEventPaginatedQuery} from "../../../redux/api/events";
import {skipToken} from "@reduxjs/toolkit/query";
import {useEventContext} from "../../../context/event/EventContext";
import {ColumnsType} from "antd/lib/table";
import {AssetAddress, GetAssetsOfTheHistoryOfTheEvent, REDUCED_STATES} from "../../../types/api/getEventsResult";
import {capitalize} from "lodash";
import {getAssetAddress} from "../../../utils/assets";
import {ClusterOption} from "../../events/importAlertsAreas/AssignCluster";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";
import AssetCommunicationView from "./AssetCommunicationView";
import AssetCommunicationCreate from "./AssetCommunicationCreate";
import CommunicationUpload from "./CommunicationUpload";

export default function AssetsCommunications() {

  const {t} = useTranslation()
  const {event, getClusterFromUuid} = useEventContext()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center', marginTop: '2rem'},
  });

  const {data, isFetching, isUninitialized} = useGetAllTheAssetsOfTheHistoryOfTheEventPaginatedQuery(event?.uuid ? {
    eventUuid: event.uuid,
    page: pagination.current,
    page_size: pagination.pageSize,
  } : skipToken)

  const columns: ColumnsType<GetAssetsOfTheHistoryOfTheEvent> = useMemo(() => [
    {
      title: capitalize(t('assets.sigiCode')),
      dataIndex: ['asset', 'sigi_code'],
      key: 'sigi_code',
    },
    {
      title: capitalize(t('address.address')),
      dataIndex: 'asset',
      key: 'asset',
      render: (asset: AssetAddress) => getAssetAddress(asset)
    },
    {
      title: capitalize(t('events.clusterCurrent')),
      dataIndex: 'cluster_template',
      key: 'cluster_template',
      render: (_, record) => {
        const cluster = record.cluster_template ? getClusterFromUuid(record.cluster_template) : null
        return cluster ? <ClusterOption cluster={cluster}/> : VALUE_NOT_PRESENT
      }
    },
    {
      title: capitalize(t('events.communicationDraft', {count: 2})),
      dataIndex: 'draft_count',
      key: 'draft_count',
    },
    {
      title: capitalize(t('events.communicationSent', {count: 2})),
      dataIndex: 'sent_count',
      key: 'sent_count',
    },
    {
      title: capitalize(t('events.communicationReceived', {count: 2})),
      dataIndex: 'received_count',
      key: 'received_count',
    },
    {
      key: 'action',
      render: (_, record) => (
        <Space>
          <AssetCommunicationView asset={record.asset}/>
          {event?.reduced_state !== REDUCED_STATES.archived && !!record.cluster_template && <AssetCommunicationCreate asset={record.asset.uuid}/>}
          {event?.reduced_state !== REDUCED_STATES.archived && !!record.cluster_template && <CommunicationUpload type={'asset'} uuid={record.asset.uuid} eventUuid={event?.uuid}/>}
        </Space>
      ),
    },
  ], [event?.reduced_state, event?.uuid, getClusterFromUuid, t])


  return <>
    <Table
      <GetAssetsOfTheHistoryOfTheEvent>
      dataSource={data?.results}
      rowKey={record => record.asset.uuid}
      loading={isFetching || isUninitialized}
      pagination={false}
      columns={columns}
      scroll={{x: true}}
    />
    {!!data?.count && <Pagination
      {...pagination}
      total={data?.count}
    />}
  </>
}