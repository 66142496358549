import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {OptionLabelValue} from "../../../types/internal/OptionLabelValue";
import {parseOptions} from "../../../redux/api/assets";
import {useTranslation} from "react-i18next";
import {capitalize, cloneDeep} from "lodash";
import NEWListWithSearchFilter from "../../common/filters/NEWListWithSearchFilter";
import {fromFeToBeUogContactsTableFilters, UogContactsFiltersFE} from "../../../types/internal/tables/uogContactsTable";
import {useLazyGetUogContactsRelatedOptionsForInfiniteScrollingQuery} from "../../../redux/api/uogContact";
import {getTranslationUogContactsColumnKey} from "../../../utils/translation";

export default function UogContactsRelatedOptionsFilter({field, assetUuid, filters, setFilters}: {
  assetUuid: string,
  field: 'full_name' | 'email' | 'uog_code' | 'company_name' | 'soa_code' | 'person_in_charge' | 'sigi_code',
  filters: UogContactsFiltersFE,
  setFilters: Dispatch<SetStateAction<UogContactsFiltersFE>>
}) {

  const [getData, {
    data,
    isLoading,
    isFetching,
    isUninitialized,
    originalArgs,
  }] = useLazyGetUogContactsRelatedOptionsForInfiniteScrollingQuery()
  const [querySearch, setQuerySearch] = useState<string>('');
  const options: OptionLabelValue[] = useMemo(() => parseOptions(data?.results || []), [data?.results]);
  const total = data?.count || 0;
  const {t} = useTranslation();
  const placeholder = capitalize(t('table.filterPlaceholder', {column: t(getTranslationUogContactsColumnKey(field))}));
  const selectedFilters = filters[field] || [];

  const isExclude = filters.excludedFilters.some(el => el === field)

  const {[field]: currentFilter, ...uogContactsFiltersWithoutCurrentFilter} = filters
  const uogContactsTableFiltersWithoutCurrentFilterRef = useRef<UogContactsFiltersFE>(uogContactsFiltersWithoutCurrentFilter)

  useEffect(() => {
    const {[field]: currentFilter, ...uogContactsFiltersWithoutCurrentFilter} = filters
    uogContactsTableFiltersWithoutCurrentFilterRef.current = uogContactsFiltersWithoutCurrentFilter
  }, [field, filters]);

  const handleGetNext = useCallback(() => {
    getData({
      column: field,
      ...!!querySearch && {column_search: querySearch},
      ...fromFeToBeUogContactsTableFilters(uogContactsTableFiltersWithoutCurrentFilterRef.current),
      page: (originalArgs?.page || 0) + 1,
      page_size: 15,
      assetUuid
    });
  }, [assetUuid, field, getData, originalArgs?.page, querySearch]);

  useEffect(() => {
    getData({
      column: field,
      ...!!querySearch && {column_search: querySearch},
      ...fromFeToBeUogContactsTableFilters(uogContactsTableFiltersWithoutCurrentFilterRef.current),
      page: 1,
      page_size: 15,
      assetUuid
    });
  }, [assetUuid, field, getData, querySearch]);

  return <>
    <NEWListWithSearchFilter
      getNext={handleGetNext}
      options={options}
      selectedOptions={selectedFilters}
      setSelectedOptions={values => {
        if (values.length) {
          setFilters(prevState => ({...prevState, [field]: values}));
        } else {
          setFilters(prevState => {
            const copyState = cloneDeep(prevState)
            delete copyState[field]
            return copyState
          })
        }
      }}
      total={total}
      isLoadingOptions={isLoading || isFetching || isUninitialized}
      querySearch={querySearch}
      setQuerySearch={setQuerySearch}
      search={{placeholder}}

      isExcludeFeature={true}
      isExclude={isExclude}
      setExcludeMode={() => {
        setFilters(prevState => {
          const copyState = cloneDeep(prevState)
          delete copyState[field]
          return {
            ...copyState,
            excludedFilters: [...prevState.excludedFilters, field]
          }
        })
      }}
      setDeclarativeMode={() => {
        setFilters(prevState => {
          const copyState = cloneDeep(prevState)
          delete copyState[field]
          return {
            ...copyState,
            excludedFilters: prevState.excludedFilters.filter(el => el !== field)
          }
        })
      }}
    />
  </>;
}