import CommunicationCreateUpload, {CommunicationUploadForm} from "./CommunicationCreateUpload";
import React, {useState} from "react";
import {Button, Col, Drawer, Form, Row, Space, Tooltip} from "antd";
import {useCreateCommunicationWithUploadMutation} from "../../../redux/api/events";
import {RcFile} from "antd/lib/upload";
import {CommunicationStatus} from "../../../types/api/communications";
import {capitalize} from "lodash";
import {useMessageContext} from "../../../context/message/MessageContext";
import {useTranslation} from "react-i18next";
import {UploadOutlined} from "@ant-design/icons";

export default function CommunicationUpload({type, uuid, eventUuid}: {
  type: 'asset' | 'cluster',
  uuid?: string,
  eventUuid?: string
}) {

  const setMessage = useMessageContext()
  const {t} = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [uploadForm] = Form.useForm()
  const [createCommunicationWithUpload, {isLoading: isLoadingCreateCommunicationWithUpload}] = useCreateCommunicationWithUploadMutation()

  const reset = () => {
    uploadForm.resetFields()
  }

  const handleCreationWithUpload = async (values: {
    // subject: string,
    tags?: string[],
    // datetime: string,
    email_file: RcFile,
    status: CommunicationStatus,
  }) => {
    if (eventUuid && uuid) {
      try {
        const request = {
          ...type === 'cluster' && {cluster_templates: [uuid]},
          ...type === 'asset' && {assets: [uuid]},
          // datetime: values.datetime,
          email_file: values.email_file,
          event: eventUuid,
          // subject: values.subject,
          status: values.status,
          tags: values.tags,
        }
        await createCommunicationWithUpload(request).unwrap()
        setMessage({success: capitalize(t('form.creationSuccessMessage'))})
        setIsDrawerOpen(false)
      } catch {
        setMessage({error: capitalize(t('form.creationFailMessage'))})
      }
    }
  }

  const handleUploadSubmit = async (type: CommunicationStatus) => {
    try {
      await uploadForm.validateFields()
      const values: CommunicationUploadForm = uploadForm.getFieldsValue()
      if (values.email_file.length && values.email_file[0].originFileObj) {
        await handleCreationWithUpload({
          ...values,
          email_file: values.email_file[0].originFileObj,
          status: type,
          // datetime: values.datetime.toISOString()
        })
        reset()
      }

    } catch {
    }

  }

  return <>
    <Drawer
      size={'large'}
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      title={capitalize(t('actions.uploadItem', {item: t('events.communication', {count: 1})}))}
      footer={<Row justify={'end'}>
        <Col>
          <Space>
            {type === 'asset' && <Button
              disabled={isLoadingCreateCommunicationWithUpload}
              loading={isLoadingCreateCommunicationWithUpload}
              onClick={() => handleUploadSubmit(CommunicationStatus.received)}>
              {capitalize(t('events.communicationLoadAsReceived'))}
            </Button>}
            <Button
              type={'primary'}
              disabled={isLoadingCreateCommunicationWithUpload}
              loading={isLoadingCreateCommunicationWithUpload}
              onClick={() => handleUploadSubmit(CommunicationStatus.sent)
              }>
              {capitalize(t('events.communicationLoadAsSent'))}
            </Button>
          </Space>
        </Col>
      </Row>}
    >
      <CommunicationCreateUpload form={uploadForm}/>
    </Drawer>
    <Tooltip
      title={capitalize(t('actions.uploadItem', {item: t('events.communication', {count: 2})}))}
    >
      <Button
        onClick={() => setIsDrawerOpen(true)}
        icon={<UploadOutlined/>}
        type={'text'}
      />
    </Tooltip>
  </>
}