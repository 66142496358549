import {Card, Col, Row, Tabs, TabsProps, Typography} from 'antd';
import {capitalize} from 'lodash';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AssetsCommunications from './AssetsCommunications';
import ClustersCommunications from './ClustersCommunications';

enum CommunicationTabs {
  clusters = 'clusters',
  assets = 'assets',
}

export default function Communications() {
  
  const {t} = useTranslation();
  const [
    currentActiveTab,
    setCurrentActiveTab,
  ] = useState<CommunicationTabs>(CommunicationTabs.assets);
  const items: TabsProps['items'] = useMemo(() => [
    {
      key: CommunicationTabs.assets,
      label: capitalize(t('assets.asset', {count: 2})),
    },
    {
      key: CommunicationTabs.clusters,
      label: capitalize(t('clusters.cluster', {count: 2})),
    },
  ], [t]);

  return <Card>
    <Row align={'middle'} justify={'space-between'} style={{marginBottom: '1rem'}}>
      <Col>
        <Typography.Title level={4} style={{marginTop: 0}}>
          {capitalize(t('events.communication', {count: 2}))}
        </Typography.Title>
      </Col>
      <Col>
        <Tabs
          type={'card'}
          activeKey={currentActiveTab}
          items={items}
          onChange={(e) => setCurrentActiveTab(CommunicationTabs[e as keyof typeof CommunicationTabs])}
        />
      </Col>
    </Row>
    {currentActiveTab === CommunicationTabs.assets && (
      <AssetsCommunications/>
    )}
    {currentActiveTab === CommunicationTabs.clusters && (
      <ClustersCommunications/>
    )}
  </Card>;
}