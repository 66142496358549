import CommunicationViewDrawer from "./CommunicationViewDrawer";
import {EyeOutlined} from "@ant-design/icons";
import {capitalize} from "lodash";
import {Avatar, Button, Space, Tooltip, Typography} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Cluster} from "../../../types/api/clusters";
import {useGetClusterCommunicationsPaginatedQuery} from "../../../redux/api/events";
import {useEventContext} from "../../../context/event/EventContext";
import {skipToken} from "@reduxjs/toolkit/query";
import {PaginationConfig} from "antd/lib/pagination";
import dayjs from "dayjs";
import {ClusterTabs, CommunicationStatusTabs} from "./CommunicationTabs";
import {CommunicationStatus} from "../../../types/api/communications";

export default function ClusterCommunicationView({cluster}: { cluster: Cluster }) {

  const {t} = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {event} = useEventContext()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center', marginTop: '2rem'},
  });
  const [communicationStatusTab, setCommunicationStatusTab] = useState<ClusterTabs>(CommunicationStatusTabs.sent)
  const isParamsOk = isDrawerOpen && event?.uuid
  const [datetimeBefore, setDatetimeBefore] = useState("")
  const [datetimeAfter, setDatetimeAfter] = useState("")

  //get cluster communication
  const {data, isFetching, isUninitialized} = useGetClusterCommunicationsPaginatedQuery(
    isParamsOk ? {
        ...!!datetimeAfter && {datetime_after: datetimeAfter},
        ...!!datetimeBefore && {datetime_before: datetimeBefore},
        eventUuid: event?.uuid,
        clusterUuid: cluster.uuid,
        page: pagination.current,
        page_size: pagination.pageSize,
        status: communicationStatusTab === CommunicationStatusTabs.sent ?
          [CommunicationStatus.sent] : [CommunicationStatus.draft],
      }
      : skipToken)

  return <>
    <CommunicationViewDrawer
      communicationTab={communicationStatusTab}
      tabs={{
        activeKey: communicationStatusTab,
        onChange: (value) => setCommunicationStatusTab(value as ClusterTabs)
      }}
      list={{
        dataSource: data?.results,
        loading: isUninitialized || isFetching,
        ...!!data?.count && {
          pagination: {
            total: data?.count,
            ...pagination
          }
        }
      }}
      datePickerAfter={{
        value: datetimeAfter ? dayjs(datetimeAfter) : undefined,
        onChange: (v) => {
          setDatetimeAfter(v?.startOf('day').toISOString() || "")
        }
      }}
      datePickerBefore={{
        value: datetimeBefore ? dayjs(datetimeBefore) : undefined,
        onChange: (v) => {
          setDatetimeBefore(v?.endOf('day').toISOString() || "")
        }
      }}
      withReceived={false}
      drawer={{
        open: isDrawerOpen,
        title: <Space>
          <Avatar
            style={{backgroundColor: cluster.color_code}}/>
          <Typography.Title level={4} style={{margin: 0}}>
            {capitalize(t('events.communication', {count: 2}))} {cluster.name}
          </Typography.Title>
        </Space>,
        onClose: () => setIsDrawerOpen(false)
      }}
    />
    <Tooltip
      title={capitalize(t('actions.viewItem', {item: t('events.communication', {count: 2})}))}
    >
      <Button
        onClick={() => setIsDrawerOpen(true)}
        icon={<EyeOutlined/>}
        type={'text'}
      />
    </Tooltip>
  </>
}