import {useEventContext} from "../../context/event/EventContext";
import {Button, Checkbox, CheckboxProps, Modal, Typography} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {useLazyDownloadEventAssetsReportQuery} from "../../redux/api/events";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useMessageContext} from "../../context/message/MessageContext";
import React, {useMemo, useState} from "react";

export default function EventAssetsDownloadButton() {
  const {event} = useEventContext()
  const {t} = useTranslation()
  const setMessage = useMessageContext()
  const [getReport, {isFetching}] = useLazyDownloadEventAssetsReportQuery()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [values, setValues] = useState<CheckboxProps['value'][]>(["asset", "uog"])
  const isAsset = values.some(el => el === 'asset')
  const isUog = values.some(el => el === 'uog')

  const handleClick = () => {
    if (event?.uuid) {
      getReport({eventUuid: event.uuid, get_assets_report: isAsset, get_uog: isUog})
        .then(res => {
          setMessage({
            success: capitalize(t('documents.downloadSuccess'))
          })
        })
        .catch(err => {
          setMessage({
            error: capitalize(t('documents.downloadError'))
          })
        })
    }
  }

  const modalDownload = useMemo(() => {
    return <Modal
      open={isOpenModal}
      onCancel={() => setIsOpenModal(false)}
      onOk={handleClick}
      okButtonProps={{
        disabled: (!isAsset && !isUog) || isFetching,
        loading: isFetching,
      }}
    >
      <Typography.Paragraph>{(t('table.downloadText'))}</Typography.Paragraph>
      <Checkbox.Group
        style={{marginTop: '1rem', display: ' flex', gap: '1rem', flexDirection: 'column'}}
        options={[
          {
            label: <><Typography.Text
              style={{fontWeight: 'bold'}}
            >{capitalize(t('assets.asset', {count: 2}))}</Typography.Text><br/>{capitalize(t('events.assetsOption'))}</>,
            value: 'asset',
          },
          {
            label: <><Typography.Text
              style={{fontWeight: 'bold'}}
            >{capitalize(t('events.uogOptionTitle', {count: 2}))}</Typography.Text><br/>{capitalize(t('events.uogOption'))}</>,
            value: 'uog',
          },
        ]}
        onChange={values => setValues(values)}
        value={values}
      />
    </Modal>
  }, [handleClick, isAsset, isFetching, isOpenModal, isUog, t, values])

  return <>
    {modalDownload}
    <Button
      block
      disabled={isFetching}
      loading={isFetching}
      icon={<DownloadOutlined/>}
      onClick={() => setIsOpenModal(true)}
    >
      <Typography.Text>{capitalize(t('events.downloadAssets'))}</Typography.Text>
    </Button>
  </>
}