import {apiSlice} from './baseApi';
import {GenericPaginatedApi} from '../../types/api/paginatedApi';
import {GetUogContactParams, UogContact, UogContactsRelatedOptionsFilterParams} from '../../types/api/uogContact';
import {AssetsRelatedOptionGet} from './assets';

const uogContactSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUogContactsPaginated: build.query<GenericPaginatedApi<UogContact>, GetUogContactParams>({
      query: ({assetUuid, ...params}) => {
        return {
          url: `/assets/${assetUuid}/uog-contacts/`,
          params
        };
      },
    }),
    getUogContactsRelatedOptionsForInfiniteScrolling: build.query<AssetsRelatedOptionGet, UogContactsRelatedOptionsFilterParams>({
      query: ({assetUuid, ...params}) => ({
          url: `/assets/${assetUuid}/uog-contacts/related_options/`,
        params
      }),
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
  }),
});
export const {useGetUogContactsPaginatedQuery, useLazyGetUogContactsRelatedOptionsForInfiniteScrollingQuery} = uogContactSlice;