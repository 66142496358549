import {OptionLabelValue} from '../OptionLabelValue';
import {GetUogContactParams} from '../../api/uogContact';

// FILTERS
const uogContactsTableOptionLabelValueFilters = [
  'full_name',
  'email',
  'uog_code',
  'company_name',
  'soa_code',
  'person_in_charge',
  'sigi_code'
] as const;
export type UogContactsOptionLabelValueFilters = (typeof uogContactsTableOptionLabelValueFilters)[number];
export const isUogContactsOptionLabelValueFilters = (x: any): x is UogContactsOptionLabelValueFilters => uogContactsTableOptionLabelValueFilters.includes(x);

export type UogContactsFiltersFE = Partial<Record<UogContactsOptionLabelValueFilters, OptionLabelValue[]>> & {
  excludedFilters: UogContactsOptionLabelValueFilters[]
}

export function fromFeToBeUogContactsTableFilters(tableFilters: UogContactsFiltersFE) {
  let parsedFilters: Omit<GetUogContactParams, 'ordering' | 'person_in_charge' | 'assetUuid'> = {};
  for (const [key, value] of Object.entries(tableFilters)) {
    if (isUogContactsOptionLabelValueFilters(key)) {
      const parsedValue = (value as OptionLabelValue[]).map(el => el.value).join(',');
      parsedFilters = {
        ...parsedFilters,
        [!tableFilters.excludedFilters.includes(key) ? key : `${key}_not`]: parsedValue,
      };
    } else {
      if (key !== 'excludedFilters') {
        parsedFilters = {...parsedFilters, [key]: value};
      }
    }
  }
  return parsedFilters;
}

// COLUMNS
export const uogContactsColumnsTable = [
  'full_name',
  'email',
  'uog_code',
  'company_name',
  'soa_code',
  'person_in_charge',
  'sigi_code'
] as const;

export type UogContactsTableColumn = (typeof uogContactsColumnsTable)[number]
