import {Button, Col, Drawer, DrawerProps, Form, Row, Skeleton, Space, Typography} from "antd";
import React, {useEffect, useState} from "react";
import TemplateTable from "./TemplateTable";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useGetEventTemplateMailQuery} from "../../../redux/api/events";
import {useEventContext} from "../../../context/event/EventContext";
import {skipToken} from "@reduxjs/toolkit/query";
import CommunicationForm from "./CommunicationForm";
import {CommunicationStatus, CreateCommunicationWithTextEditor} from "../../../types/api/communications";


enum Step {
  list,
  write
}

type StepCreation =
  {
    type: Step.list,
    selectedTemplate: null,
  } |
  {
    type: Step.write,
    selectedTemplate: null,
  } |
  {
    type: Step.write,
    selectedTemplate: string,
  }

export default function CommunicationCreateDrawer({
                                                    drawer,
                                                    onCreationWithTextEditor,
                                                    disabled,
                                                  }: {
  drawer?: DrawerProps,
  onCreationWithTextEditor: (values: Pick<CreateCommunicationWithTextEditor, 'body' | 'subject' | 'tags' | 'status'>) => Promise<void>,
  disabled?: boolean
}) {

  const {t} = useTranslation()
  const [step, setStep] = useState<StepCreation>({selectedTemplate: null, type: Step.list})
  const {event} = useEventContext()
  const areParamsOk = event?.uuid && step.selectedTemplate
  const {data: eventTemplate, isFetching: isFetchingEventTemplate} = useGetEventTemplateMailQuery(areParamsOk ? {
    eventUuid: event?.uuid,
    templateUuid: step.selectedTemplate
  } : skipToken)

  const [textEditorForm] = Form.useForm()
  const reset = () => {
    // reset text-editor form values
    if (step.type === Step.write) {
      textEditorForm.resetFields()
    }
    setStep({type: Step.list, selectedTemplate: null})
  }
  const handleWriteSubmit = async (type: CommunicationStatus.draft | CommunicationStatus.sent) => {
    try {
      await textEditorForm.validateFields()
      const values = textEditorForm.getFieldsValue()
      await onCreationWithTextEditor({
        ...values,
        status: type
      })
      reset()
    } catch {
    }

  }

  const handleSelectTemplate = (template: string | null) => {
    setStep({selectedTemplate: template, type: Step.write})
  }

  let props: DrawerProps = {};

  switch (step.type) {
    case Step.list:
      props = {
        title: capitalize(t('mailTemplate.sendCommunications')),
        footer: <Row justify={'end'}>
          <Col>
            <Space>
              <Button
                type={'primary'}
                onClick={() => setStep({selectedTemplate: null, type: Step.write})}>
                {capitalize(t('events.communicationWithoutTemplate'))}
              </Button>
            </Space>
          </Col>
        </Row>
      }
      break;
    default:
      props = {
        title: <Button
          type={'link'}
          onClick={reset} style={{padding: 0}}>
          <Space size={16}>
            <ArrowLeftOutlined/>
            <Typography.Text>{capitalize(t('actions.goBack'))}</Typography.Text>
          </Space>
        </Button>,
        closeIcon: null,
        footer: <Row justify={'end'}>
          <Col>
            <Space>
              {step.type === Step.write && <>
                <Button
                  disabled={disabled}
                  loading={disabled}
                  onClick={() => handleWriteSubmit(CommunicationStatus.draft)}>
                  {capitalize(t('events.communicationSave'))}
                </Button>
                <Button
                  type={'primary'}
                  disabled={disabled}
                  loading={disabled}
                  onClick={() => handleWriteSubmit(CommunicationStatus.sent)}
                >
                  {capitalize(t('mailTemplate.sendCommunication'))}
                </Button>
              </>}

            </Space>
          </Col>
        </Row>
      }
      break;
  }

  useEffect(() => {
    // to update text-editor form values
    if (step.type === Step.write && step.selectedTemplate && !isFetchingEventTemplate) {
      textEditorForm.setFieldsValue(eventTemplate)
    }
  }, [eventTemplate, isFetchingEventTemplate, step.selectedTemplate, step.type, textEditorForm]);


  return <>
    <Drawer
      size={'large'}
      {...props}
      {...drawer}
      onClose={(e) => {
        if (drawer?.onClose) {
          drawer.onClose(e)
          reset()
        }
      }}
    >
      {step.type === Step.list && <TemplateTable onSelectTemplate={handleSelectTemplate}/>}

      {step.type === Step.write && !step.selectedTemplate && <CommunicationForm
        form={textEditorForm}
      />}

      {step.type === Step.write && step.selectedTemplate && <>
        {isFetchingEventTemplate && <Skeleton/>}
        {!isFetchingEventTemplate && <CommunicationForm
          key={step.selectedTemplate}
          form={textEditorForm}
        />}
      </>}
    </Drawer>
  </>
}