import {Card, Col, Pagination, Row, Table} from 'antd';
import {useTranslation} from 'react-i18next';
import React, {useCallback, useMemo, useState} from 'react';
import {PaginationConfig} from 'antd/lib/pagination';
import {ColumnsType} from 'antd/lib/table';
import {useGetUogContactsPaginatedQuery} from '../../../redux/api/uogContact';
import {UogContact} from '../../../types/api/uogContact';
import {useAssetDetails} from '../../../context/assetDetails/AssetDetails';
import _, {capitalize, cloneDeep} from 'lodash';
import {skipToken} from "@reduxjs/toolkit/query";
import {DocumentsTableColumnHeader} from "../../documents/TablesViews/DocumentsTableColumnHeader";
import {
  fromFeToBeUogContactsTableFilters,
  UogContactsFiltersFE,
  UogContactsTableColumn
} from "../../../types/internal/tables/uogContactsTable";
import UogContactsRelatedOptionsFilter from "./UogContactsRelatedOptionsFilter";
import {Sorter} from "../../../types/internal/tables/sorters";
import TableSorter from "../../common/TableSorter";
import {fromFeToBeSorter} from "../../../utils/parser";
import AssetUogReportDownload from "./AssetUogReportDownload";

const UOGTable = () => {
  const {t} = useTranslation();
  const [, {asset}] = useAssetDetails();
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'right'},
  });

  const [filters, setFilters] = useState<UogContactsFiltersFE>({
    excludedFilters: []
  })
  const [sorters, setSorters] = useState<{ [column in UogContactsTableColumn]: Sorter }>({
    'full_name': null,
    'email': null,
    'uog_code': null,
    'company_name': null,
    'soa_code': null,
    'person_in_charge': null,
    'sigi_code': null,
  })


  const {
    data: uogContacts,
    isFetching: isFetchingUogContacts,
    isUninitialized: isUninitializedUogContacts,
  } = useGetUogContactsPaginatedQuery(asset.uuid ? {
    page: pagination.current,
    page_size: pagination.pageSize,
    assetUuid: asset.uuid,
    ...fromFeToBeUogContactsTableFilters(filters),
    ...(!_.isEmpty(fromFeToBeSorter(sorters)) ? {
      ordering: fromFeToBeSorter(sorters),
    } : {}),
  } : skipToken);


  const resetFilter = useCallback((filter: keyof UogContactsFiltersFE) => {
    setFilters(prevState => {
      const copyState = cloneDeep(prevState)
      delete copyState[filter]
      return copyState
    })
    setPagination(prevState => ({...prevState, current: 1}))
  }, [])

  const columns: ColumnsType<UogContact> = useMemo(() => [
    {
      dataIndex: 'full_name',
      key: 'full_name',
      title: <DocumentsTableColumnHeader
        sorter={<TableSorter
          value={sorters.full_name}
          onChange={type => setSorters(prevState => ({...prevState, full_name: type}))}
        />}
        title={capitalize(t('contacts.fullName'))}
        filter={asset?.uuid && <UogContactsRelatedOptionsFilter
          filters={filters}
          setFilters={(params) => {
            setFilters(params)
            setPagination(prevState => ({...prevState, current: 1}))
          }}
          assetUuid={asset?.uuid}
          field={"full_name"}
        />}
        isFilterDisabled={filters["full_name"] === undefined}
        onFilterReset={() => resetFilter("full_name")}
      />,
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: <DocumentsTableColumnHeader
        title={capitalize(t('contacts.email'))}
        sorter={<TableSorter
          value={sorters.email}
          onChange={type => setSorters(prevState => ({...prevState, email: type}))}
        />}
        filter={asset?.uuid && <UogContactsRelatedOptionsFilter
          filters={filters}
          setFilters={(params) => {
            setFilters(params)
            setPagination(prevState => ({...prevState, current: 1}))
          }}
          assetUuid={asset?.uuid}
          field={"email"}
        />}
        isFilterDisabled={filters["email"] === undefined}
        onFilterReset={() => resetFilter("email")}
      />,
    },
    {
      dataIndex: 'sigi_code',
      key: 'sigi_code',
      title: <DocumentsTableColumnHeader
        sorter={<TableSorter
          value={sorters.sigi_code}
          onChange={type => setSorters(prevState => ({...prevState, sigi_code: type}))}
        />}
        title={capitalize(t('contacts.sigiCode'))}
        filter={asset?.uuid && <UogContactsRelatedOptionsFilter
          filters={filters}
          setFilters={(params) => {
            setFilters(params)
            setPagination(prevState => ({...prevState, current: 1}))
          }}
          assetUuid={asset?.uuid}
          field={"sigi_code"}
        />}
        isFilterDisabled={filters["sigi_code"] === undefined}
        onFilterReset={() => resetFilter("sigi_code")}
      />,
    },
    {
      dataIndex: 'person_in_charge',
      key: 'person_in_charge',
      title: <DocumentsTableColumnHeader
        title={capitalize(t('contacts.personInCharge'))}
        sorter={<TableSorter
          value={sorters.person_in_charge}
          onChange={type => setSorters(prevState => ({...prevState, person_in_charge: type}))}
        />}
        filter={asset?.uuid && <UogContactsRelatedOptionsFilter
          filters={filters}
          setFilters={(params) => {
            setFilters(params)
            setPagination(prevState => ({...prevState, current: 1}))
          }}
          assetUuid={asset?.uuid}
          field={"person_in_charge"}
        />}
        isFilterDisabled={filters["person_in_charge"] === undefined}
        onFilterReset={() => resetFilter("person_in_charge")}
      />,
      render: value => value ? 'SÌ' : 'NO',
    },
    {
      dataIndex: 'uog_code',
      key: 'uog_code',
      title: <DocumentsTableColumnHeader
        title={capitalize(t('contacts.uogCode'))}
        sorter={<TableSorter
          value={sorters.uog_code}
          onChange={type => setSorters(prevState => ({...prevState, uog_code: type}))}
        />}
        filter={asset?.uuid && <UogContactsRelatedOptionsFilter
          filters={filters}
          setFilters={(params) => {
            setFilters(params)
            setPagination(prevState => ({...prevState, current: 1}))
          }}
          assetUuid={asset?.uuid}
          field={"uog_code"}
        />}
        isFilterDisabled={filters["uog_code"] === undefined}
        onFilterReset={() => resetFilter("uog_code")}
      />,
      render: (value) => <div style={{whiteSpace: 'nowrap'}}>{value}</div>
    },
    {
      dataIndex: "company_name",
      key: 'company_name',
      title: <DocumentsTableColumnHeader
        title={capitalize(t('contacts.company'))}
        sorter={<TableSorter
          value={sorters.company_name}
          onChange={type => setSorters(prevState => ({...prevState, company_name: type}))}
        />}
        filter={asset?.uuid && <UogContactsRelatedOptionsFilter
          filters={filters}
          setFilters={(params) => {
            setFilters(params)
            setPagination(prevState => ({...prevState, current: 1}))
          }}
          assetUuid={asset?.uuid}
          field={"company_name"}
        />}
        isFilterDisabled={filters["company_name"] === undefined}
        onFilterReset={() => resetFilter("company_name")}
      />,
      render: (value) => <div style={{whiteSpace: 'nowrap'}}>{value}</div>
    },
    {
      dataIndex: "soa_code",
      key: 'soa_code',
      title: <DocumentsTableColumnHeader
        title={capitalize(t('contacts.soaCode'))}
        sorter={<TableSorter
          value={sorters.soa_code}
          onChange={type => setSorters(prevState => ({...prevState, soa_code: type}))}
        />}
        filter={asset?.uuid && <UogContactsRelatedOptionsFilter
          filters={filters}
          setFilters={(params) => {
            setFilters(params)
            setPagination(prevState => ({...prevState, current: 1}))
          }}
          assetUuid={asset?.uuid}
          field={"soa_code"}
        />}
        isFilterDisabled={filters["soa_code"] === undefined}
        onFilterReset={() => resetFilter("soa_code")}
      />,
    },
  ], [asset?.uuid, filters, resetFilter, sorters.company_name, sorters.email, sorters.full_name, sorters.person_in_charge, sorters.sigi_code, sorters.soa_code, sorters.uog_code, t]);

  return (
    <Card
      title={capitalize(t('contacts.staff'))}
      extra={<AssetUogReportDownload
        assetUuid={asset.uuid}
      />}
    >
      <Table
        <UogContact>
        loading={isFetchingUogContacts || isUninitializedUogContacts}
        dataSource={uogContacts?.results}
        rowKey={'email'}
        columns={columns}
        bordered={false}
        scroll={{x: 576}}
        pagination={false}
      />
      <Row
        justify={'center'}
        style={{marginTop: '1rem'}}
      >
        <Col>
          {!!uogContacts?.count && <Pagination
            {...pagination}
            total={uogContacts?.count}
          />}
        </Col>
      </Row>
    </Card>
  );
};

export default UOGTable;