import {Button, Col, Flex, Pagination, Popover, Row, Space, Table, Typography} from "antd";
import {ColumnsType} from "antd/lib/table";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {PaginationConfig} from "antd/lib/pagination";
import {useGetEventAssetStatusPaginatedQuery} from "../../../redux/api/events";
import {skipToken} from "@reduxjs/toolkit/query";
import {Trans, useTranslation} from "react-i18next";
import {useEventContext} from "../../../context/event/EventContext";
import {
  fromFeToBeInvolvedAssetsTableFilters,
  involvedAssetsColumnsTable,
  InvolvedAssetsFiltersFE,
  InvolvedAssetsTableColumn
} from "../../../types/internal/tables/involvedAssetsTable";
import {ASSET_STATUS, GetAssetStatus, REDUCED_STATES} from "../../../types/api/getEventsResult";
import {DocumentsTableColumnHeader} from "../../documents/TablesViews/DocumentsTableColumnHeader";
import {getTranslationInvolvedAssetTableColumnKey} from "../../../utils/translation";
import {capitalize, cloneDeep} from "lodash";
import InvolvedAssetRelatedOptionsFilter from "./InvolvedAssetRelatedOptionsFilter";
import {FileTextOutlined} from "@ant-design/icons";
import AssetStatusEdit from "../eventStatus/AssetStatusEdit";
import {TableRowSelection} from "antd/lib/table/interface";
import {isEventEdit} from "../../Authentication/permissions";
import {useGetUserProfileQuery} from "../../../redux/api/locales";
import EventAssetNote from "../EventAssetNote";

export default function InvolvedAssetsTable() {

  const {t} = useTranslation()
  const {event} = useEventContext()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center'},
  });
  const [filters, setFilters] = useState<InvolvedAssetsFiltersFE>({
    excludedFilters: []
  })


  const resetFilter = useCallback((filter: keyof InvolvedAssetsFiltersFE) => {
    setFilters(prevState => {
      const copyState = cloneDeep(prevState)
      delete copyState[filter]
      return copyState
    })
    setPagination(prevState => ({...prevState, current: 1}))
  }, [])

  const {data, isFetching} = useGetEventAssetStatusPaginatedQuery(event?.uuid ? {
    eventUuid: event.uuid,
    page: pagination.current,
    page_size: pagination.pageSize,
    ...fromFeToBeInvolvedAssetsTableFilters(filters)
  } : skipToken)

  const {
    data: userData,
  } = useGetUserProfileQuery();

  const getAddressColumnProps = useCallback((column: InvolvedAssetsTableColumn) => {
    return {
      key: column,
      dataIndex: ['asset', column],
      title: <DocumentsTableColumnHeader
        title={capitalize(t(getTranslationInvolvedAssetTableColumnKey(column)))}
        filter={event?.uuid && <InvolvedAssetRelatedOptionsFilter
          filters={filters}
          setFilters={params => {
            setFilters(params)
            setPagination(prevState => ({...prevState, current: 1}))
          }}
          eventUuid={event?.uuid}
          field={column}
        />}
        isFilterDisabled={filters[column] === undefined}
        onFilterReset={() => resetFilter(column)}
      />,
      ...["address", "admin_level_1", "admin_level_3", "country"]
        .includes(column) && {
        render: (value: string | null) => <div
          style={{textTransform: 'capitalize'}}>
          {value ? value.toLowerCase() : ''}
        </div>
      },
    }
  }, [event?.uuid, filters, resetFilter, t])

  const columns: ColumnsType<GetAssetStatus> = useMemo(() => involvedAssetsColumnsTable.map(column => {
    if (column !== 'state') {
      return getAddressColumnProps(column)
    } else {
      return {
        key: column,
        dataIndex: column,
        title: <DocumentsTableColumnHeader
          title={capitalize(t('events.changeAssetsState', {count: 1}))}
          filter={event?.uuid && <InvolvedAssetRelatedOptionsFilter
            filters={filters}
            setFilters={(params) => {
              setFilters(params)
              setPagination(prevState => ({...prevState, current: 1}))
            }}
            eventUuid={event?.uuid}
            field={column}
          />}
          isFilterDisabled={filters[column] === undefined}
          onFilterReset={() => resetFilter(column)}
        />,
        render: (value: string, record: GetAssetStatus) => {

          let label = ''

          switch (record.state) {
            case ASSET_STATUS.OPEN:
              label = capitalize(t('assets.statusOpen', {count: 1}))
              break;
            case ASSET_STATUS.REMOTE_WORK:
              label = capitalize(t('assets.statusRemote'))
              break;
            case ASSET_STATUS.CLOSED:
              label = capitalize(t('assets.statusClosed', {count: 1}))
              break;

            case ASSET_STATUS.CLOSED_REMOTE_WORK:
              label = capitalize(t('assets.statusClosedRemote', {count: 1}))
          }

          return <Flex align={'center'} justify={'space-between'} gap={"0.5rem"}>
            {label}
            <Space align={'center'} size={'small'}>
              {!!record.notes && !!userData && !isEventEdit(userData?.type) && <Popover
                content={record.notes.text}
                title={capitalize(t('misc.note'))}
              >
                <Button
                  icon={<FileTextOutlined/>}
                  type={'text'}
                />
              </Popover>}
              {!!record.notes && !!userData && isEventEdit(userData?.type) && <EventAssetNote
                {...record.notes}
              />}
              {event?.reduced_state !== REDUCED_STATES.archived && <AssetStatusEdit
                modalButtonProps={{
                  type: 'text',
                  children: null,
                }}
                assets={[
                  {
                    asset: record.asset,
                    state: record.state,
                    state_from: record.state_from,
                    notes: record.notes
                  }
                ]}
              />}
            </Space>
          </Flex>
        }
      }
    }
  }), [event?.reduced_state, event?.uuid, filters, getAddressColumnProps, resetFilter, t])

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    if (filters || pagination) {
      setSelectedRowKeys([])
    }
  }, [filters, pagination]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<GetAssetStatus> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return <Flex vertical gap={'1rem'}>
    <Typography.Title level={4}>
      {capitalize(t('events.changeAssetsState'))}
    </Typography.Title>
    <Row justify={'space-between'} align={'middle'}>
      <Col>
        {hasSelected &&
          <Trans
            i18nKey={'assets.selected'}
            values={{
              number: selectedRowKeys.length,
            }}
            components={{p: <Typography.Text/>}}
            count={selectedRowKeys.length}
          />}
      </Col>
      <Col>
        {event?.reduced_state !== REDUCED_STATES.archived && <AssetStatusEdit
          modalButtonProps={{
            disabled: !hasSelected,
            children: capitalize(t('actions.editItem', {item: t('events.changeAssetsState')})),
            icon: undefined
          }}
          assets={data?.results.filter(el => selectedRowKeys.some(selected => selected === el.asset.uuid)) ?? []}
        />}
      </Col>
    </Row>
    <Table
      <GetAssetStatus>
      columns={columns}
      loading={isFetching}
      dataSource={data?.results}
      rowKey={item => item.asset.uuid}
      pagination={false}
      scroll={{x: true}}
      rowSelection={rowSelection}
    />
    {!!data?.count && <Pagination
      total={data?.count}
      {...pagination}
    />}
  </Flex>
}