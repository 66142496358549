import {apiSlice} from './baseApi';
import {AssetModel, CreateAssetRequestType} from '../../types/internal/AssetModel';
import {Hazard, HazardStatus} from '../../types/internal/Hazard';
import {downloadFileResponseHandler} from '../../utils/download';
import {GetEvents, GetEventsParams} from '../../types/api/getEventsResult';
import {ClimateChangeGet, GetAssetBiodiversity, GetAssetBiodiversityParams} from '../../types/api/assets';

const assetsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createAsset: build.mutation<AssetModel, CreateAssetRequestType>({
      query: (args) => {
        return {
          url: '/assets/',
          method: 'post',
          body: args,
        };
      },
    }),
    getAsset: build.query<AssetModel, { uuid: string }>({
      query: (args) => `/assets/${args.uuid}/`,
      providesTags: ['Asset'],
    }),
    getAssetBySigiCode: build.query<AssetModel[], { sigi_code: string }>({
      query: ({sigi_code}) => ({
        url: '/assets/',
        params: {
          search_by_sigi: sigi_code,
          paginate: false,
        },
      }),
    }),
    getAssetHazards: build.query<Hazard[], { assetUuid: string }>({
      query: (args) => {
        return {
          url: `/assets/${args.assetUuid}/hazard_data/`,
          params: {
            paginate: false,
          },
        };
      },
    }),
    getAssetHazard: build.query<Hazard, { assetUuid: string, hazardUuid: string }>({
      query: ({assetUuid, hazardUuid}) => {
        return {
          url: `/assets/${assetUuid}/hazard_data/${hazardUuid}/`,
        };
      },
    }),
    getAssetEvents: build.query<GetEvents, GetEventsParams & { assetUuid: string }>({
      query: ({assetUuid}) => {
        return {
          url: `/assets/${assetUuid}/events/`,
        };
      },
    }),
    getAssetBiodiversityPaginated: build.query<GetAssetBiodiversity, GetAssetBiodiversityParams>({
      query: ({assetUuid, ...params}) => {
        return {
          url: `/assets/${assetUuid}/biodiversity/`,
          params,
        };
      },
    }),
    getAssetClimateChange: build.query<ClimateChangeGet, { assetUuid: string }>({
      query: ({assetUuid}) => {
        return {
          url: `/assets/${assetUuid}/climate-change/`,
        };
      },
      providesTags: ['Locale'],
    }),
    requestHazardsUpdate: build.mutation<void, { uuid: string }>({
      query: (args) => {
        return {
          url: `/assets/${args.uuid}/get_hazards/`,
          method: 'POST',
        };
      },
    }),
    hazardsReady: build.query<HazardStatus, { assetUuid: string }>({
      query: (args) => ({
        url: `/assets/${args.assetUuid}/hazards_ready/`,
      }),
    }),
    downloadAssetReport: build.query<void, { assetUuid: string, out_format?: 'pdf' | 'docx', name: string }>({
      query: (args) => {
        const {assetUuid, out_format = 'pdf', name} = args;
        return {
          url: `/assets/${assetUuid}/vulnerability_report/`,
          params: {
            out_format,
          },
          //headers: {'Content-Type': 'application/csv'},
          responseHandler: (resp) => downloadFileResponseHandler(resp, name),
          cache: 'no-cache',
        };
      },
    }),
    downloadAssetClimateChange: build.query<void, { assetUuid: string }>({
      query: ({assetUuid}) => {
        return {
          url: `/assets/${assetUuid}/climate-change/download/`,
          //headers: {'Content-Type': 'application/csv'},
          responseHandler: downloadFileResponseHandler,
          cache: 'no-cache',
        };
      },
    }),
    downloadAssetUog: build.query<void, { assetUuid: string }>({
      query: ({assetUuid}) => {
        return {
          url: `/assets/${assetUuid}/download_uog_contacts/`,
          //headers: {'Content-Type': 'application/csv'},
          responseHandler: downloadFileResponseHandler,
          cache: 'no-cache',
        };
      },
    }),
  }),
});

export const {
  useCreateAssetMutation,
  useLazyGetAssetQuery,
  useGetAssetQuery,
  useLazyGetAssetBySigiCodeQuery,
  useRequestHazardsUpdateMutation,
  useHazardsReadyQuery,
  useGetAssetHazardsQuery,
  useLazyDownloadAssetReportQuery,
  useGetAssetEventsQuery,
  useGetAssetBiodiversityPaginatedQuery,
  useGetAssetClimateChangeQuery,
  useLazyDownloadAssetClimateChangeQuery,
  useLazyDownloadAssetUogQuery
} = assetsApi;