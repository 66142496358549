import {Button, ButtonProps} from "antd";
import {useTranslation} from "react-i18next";
import {useLazyDownloadAssetUogQuery} from "../../../redux/api/asset";
import {useMessageContext} from "../../../context/message/MessageContext";
import {capitalize} from "lodash";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {isReportsCreation} from "../../Authentication/permissions";
import {DownloadOutlined} from "@ant-design/icons";

export default function AssetUogReportDownload({assetUuid, buttonProps}: {
  assetUuid: string,
  buttonProps?: ButtonProps
}) {

  const {t} = useTranslation()
  const [download, {isFetching}] = useLazyDownloadAssetUogQuery()
  const setAntdMessage = useMessageContext()
  const handleDownload = async () => {
    try {
      await download({
        assetUuid,
      }).unwrap()
      setAntdMessage({
        success: capitalize(t('documents.downloadSuccess'))
      })
    } catch {
      setAntdMessage({
        error: capitalize(t('documents.downloadError'))
      })
    }
  }

  return <PermissionRoute isAuthorized={isReportsCreation}>
    <Button
      type={"default"}
      style={{width: "100%"}}
      icon={<DownloadOutlined/>}
      disabled={isFetching}
      loading={isFetching}
      onClick={handleDownload}
      children={capitalize(t("actions.downloadItem", {item: t('misc.report')}))}
      {...buttonProps}
    />
  </PermissionRoute>

}