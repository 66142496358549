import {useEventContext} from "../../../context/event/EventContext";
import {Avatar, Space, Table} from "antd";
import React, {useMemo} from "react";
import {Cluster} from "../../../types/api/clusters";
import ClusterCommunicationView from "./ClusterCommunicationView";
import {useGetAllTheClustersOfTheHistoryOfTheEventNotPaginatedQuery} from "../../../redux/api/events";
import {skipToken} from "@reduxjs/toolkit/query";
import ClusterCommunicationCreate from "./ClusterCommunicationCreate";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {REDUCED_STATES} from "../../../types/api/getEventsResult";
import CommunicationUpload from "./CommunicationUpload";
import {ColumnsType} from "antd/lib/table";

type ClusterHistory = Cluster & { isActive: boolean, draft: number, sent: number }

export default function ClustersCommunications() {

  const {event} = useEventContext()
  const {getClusterFromUuid} = useEventContext()

  const {
    data,
    isFetching,
    isUninitialized
  } = useGetAllTheClustersOfTheHistoryOfTheEventNotPaginatedQuery(event?.uuid ? {
    eventUuid: event.uuid,
  } : skipToken)

  const historyClusters = (data || [])?.reduce((prevVal, currVal) => {
    const foundCluster = getClusterFromUuid(currVal.cluster_template)
    return foundCluster ? [
        ...prevVal,
        {
          ...foundCluster,
          isActive: currVal.assets_count > 0,
          draft: currVal.draft_count,
          sent: currVal.sent_count
        }
      ] :
      prevVal
  }, [] as ClusterHistory[])

  const {t} = useTranslation()
  const columns: ColumnsType<ClusterHistory> = useMemo(() => {
    return [
      {
        title: capitalize(t('clusters.cluster', {count: 1})),
        key: 'cluster',
        render: (value, {color_code, name}) => <Space>
          <Avatar style={{backgroundColor: color_code}}/>
          {name}
        </Space>
      },
      {
        title: capitalize(t('events.communicationDraft', {count: 2})),
        dataIndex: 'draft',
        key: 'draft',
      },
      {
        title: capitalize(t('events.communicationSent', {count: 2})),
        dataIndex: 'sent',
        key: 'sent',
      },
      {
        key: 'action',
        render: (_, {isActive, uuid, name, color_code, description, position_order}) => (
          <Space wrap>
            <ClusterCommunicationView cluster={{uuid, name, color_code, description, position_order}}/>
            {isActive && event?.reduced_state !== REDUCED_STATES.archived &&
              <ClusterCommunicationCreate cluster={uuid}/>}
            {isActive && event?.reduced_state !== REDUCED_STATES.archived &&
              <CommunicationUpload type={'cluster'} uuid={uuid} eventUuid={event?.uuid}/>}
          </Space>
        ),
      }
    ]
  }, [event?.reduced_state, event?.uuid, t])

  return <>
    <Table
      <ClusterHistory>
      dataSource={historyClusters}
      rowKey={record => record.uuid}
      loading={isFetching || isUninitialized}
      columns={columns}
      scroll={{x: true}}
      pagination={false}
    />
  </>
}