import {capitalize} from "lodash";
import {MailOutlined} from "@ant-design/icons";
import {Button, Tooltip} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CommunicationCreateDrawer from "./CommunicationCreateDrawer";
import {useCreateCommunicationWithTextEditorMutation} from "../../../redux/api/events";
import {CreateCommunicationWithTextEditor} from "../../../types/api/communications";
import {useEventContext} from "../../../context/event/EventContext";
import dayjs from "dayjs";
import {useMessageContext} from "../../../context/message/MessageContext";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {isEventCommunicationCreation} from "../../Authentication/permissions";

export default function AssetCommunicationCreate({asset}: { asset: string }) {

  const {t} = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {event} = useEventContext()
  const [createCommunicationWithTextEditor, {isLoading: isLoadingCreateCommunicationWithTextEditor}] = useCreateCommunicationWithTextEditorMutation()
  const setMessage = useMessageContext()
  const handleCreationWithTextEditor = async (values: Pick<CreateCommunicationWithTextEditor, 'body' | 'subject' | 'tags' | 'status'>) => {
    const {body, subject, tags, status} = values
    if (event?.uuid) {
      try {
        await createCommunicationWithTextEditor({
          assets: [asset],
          body,
          datetime: dayjs().toISOString(),
          event: event.uuid,
          subject,
          status,
          tags
        }).unwrap()
        setMessage({success: capitalize(t('form.creationSuccessMessage'))})
        setIsDrawerOpen(false)
      } catch {
      }
    }
  }

  return <PermissionRoute isAuthorized={isEventCommunicationCreation}>
    <CommunicationCreateDrawer
      disabled={isLoadingCreateCommunicationWithTextEditor}
      onCreationWithTextEditor={handleCreationWithTextEditor}
      drawer={{
        open: isDrawerOpen,
        onClose: () => setIsDrawerOpen(false)
      }}
    />
    <Tooltip
      title={capitalize(t('actions.createItem', {item: t('events.communication', {count: 2})}))}
    >
      <Button
        onClick={() => setIsDrawerOpen(true)}
        icon={<MailOutlined/>}
        type={'text'}
      />
    </Tooltip>
  </PermissionRoute>
}